.panel-div {
  border-radius: 0px 15px 15px 0px;
  border-left: 1px solid #a8a8a88c;
  border-top: 3px solid #a8a8a88c;
  background-color: rgb(254, 254, 254);
  text-align: left;
  margin-top: 10px;
  display: inline-block;
  padding: 10px;
  padding-top: 15px;
  vertical-align: top;
  width: 20%;
  position: sticky;
  top: 70px;
  margin-bottom: 60px;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.noresize {
  resize: none;
}

.btn {
  box-shadow: 2px 2px 4px 1px black;
}

label {
  margin-left: 5px;
}

input[type="checkbox"] {
  cursor: pointer;

  width: 20px;
  height: 20px;
  transition: all 0.3s linear;
}
