@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rw-calendar-wrapper-custom-classname ~ .rw-calendar-popup {
  transform: translateX(-45%);
}

.ChangePasswordForm {
    margin: auto;
    margin-top: 100px;
    width: 400px;
    padding: 20px 40px 10px 40px;
    background-color: rgba(255, 255, 255, 0.719);
}
table {
    background: rgba(255, 255, 255, 0.918);
}

.table-div {
    position: relative;
    background-color: rgb(254, 254, 254);
    border-left: 3px solid #a8a8a88c;
    border-top: 3px solid #a8a8a88c;
    border-radius: 15px 0px 15px 15px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.otherTables {
    display: inline-block;
    width: 40%;
    margin-left: 30%;
    text-align: center;
}

.mediumTable {
    display: inline-block;
    width: 70%;
    margin-left: 5%;
    text-align: center;
}

.reservationTable {
    display: inline-block;
    width: 80%;
    text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: 1px solid rgba(0, 0, 0, 0) !important;
}

.ReactTable .rt-thead.-filters .rt-tr {
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none !important;
}

.ReactTable .rt-thead.-filters .rt-tr .rt-th {
    padding-left: 10px;
    padding-right: 10px;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border: 0.5px solid rgba(114, 114, 114, 0.295) !important;
    box-shadow: 1.5px 1.5px 0px 0px rgba(0, 0, 0, 0.26);
}

.ReactTable .rt-tbody .rt-td {
    border: none !important;
    border-left: 1px solid rgba(154, 149, 149, 0.07) !important;
    border-right: 1px solid rgba(154, 149, 149, 0.07) !important;
    box-shadow: 0px 4px 2px -3px rgba(0, 0, 0, 0.247);
}

.ReactTable .rt-thead.-header .rt-tr {
    box-shadow: 0px 8px 5px -3px rgba(0, 0, 0, 0.247);
    padding-bottom: 10px;
}

.ReactTable .rt-table .rt-body {
    overflow-y: scroll;
}

body {
  background-image: url(/prod/static/media/login_background.33c0d8bc.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.Login {
  margin: 0 auto;
  width: 400px;
  padding: 60px 0;
}

.Login form {
  width: 400px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.719);
  border-radius: 5px;
}

.Login button {
  width: 100%;
}

.loginFormLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 20px;
}

.panel-div {
  border-radius: 0px 15px 15px 0px;
  border-left: 1px solid #a8a8a88c;
  border-top: 3px solid #a8a8a88c;
  background-color: rgb(254, 254, 254);
  text-align: left;
  margin-top: 10px;
  display: inline-block;
  padding: 10px;
  padding-top: 15px;
  vertical-align: top;
  width: 20%;
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  margin-bottom: 60px;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.noresize {
  resize: none;
}

.btn {
  box-shadow: 2px 2px 4px 1px black;
}

label {
  margin-left: 5px;
}

input[type="checkbox"] {
  cursor: pointer;

  width: 20px;
  height: 20px;
  transition: all 0.3s linear;
}

.calendar-container {
  width: 100%;
  display: flex;
  margin-top: 10px;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
}

.calendar-container-with-filtres {
  width: 80%;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 65px;
  left: -100%;
  transition: 0.75s ease-in-out;
}

.slide {
  transform: translate(125%, 0);
}

/* FONT IMPORTS */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --arrow-hover-color: #0069ff;
  --text-color: #777;
  --text-color-light: #999;
  --border-color: #f1e8e8;
  --bg-color: #eee;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  color: var(--text-color);
  overflow: hidden;
  border-left: 3px solid #a8a8a88c;
  border-top: 3px solid #a8a8a88c;
  border-radius: 15px 0px 15px 15px;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
  margin-right: auto;
}

.filters {
  border: 3px solid #a8a8a88c;
  border-top: 1px solid #a8a8a88c;
  border-radius: 0 0 15px 15px;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 5px;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--arrow-hover-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 8em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.intersection {
  background: rgba(0, 255, 0, 0.2) !important;
  transition: 0.5s ease-out;
}

.intersection:hover {
  background: rgba(0, 200, 0, 0.3) !important;
  transition: 0.5s ease-out;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.spans-container {
  margin-left: -10px;
  width: 180px;
  font-size: 15px;
  margin-top: 10px;
}

.resource-span {
  display: flex;
  border-radius: 15px;
  margin: 3px;
  color: #fff;
  padding-left: 10px;
}

.container {
  padding: 10px;
  background-color: white;
  width: 20%;
  border-radius: 0px 15px 15px 0px;
  border-left: 1px solid #a8a8a88c;
  border-top: 3px solid #a8a8a88c;
  z-index: 100;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.selectionGroup {
  margin-top: 10px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  margin-bottom: 5px;
}

.button {
  flex: 1 1;
}

.filters-container {
  background: white;
  display: flex;
  padding: 10px;
  padding-bottom: 15px;
  flex: 1 1;
  width: 100%;
  justify-content: flex-end;
  border: solid #a8a8a88c;
  border-width: 3px 3px 0 3px;
  border-radius: 15px 15px 0 0;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.filters-container .selection-group {
  flex: 1 1;
  margin-right: 10px;
}

.filters-container .buttons-container {
  flex: 0.5 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.filters-container .buttons-container .button {
  flex: 1 1;
}

