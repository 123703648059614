table {
    background: rgba(255, 255, 255, 0.918);
}

.table-div {
    position: relative;
    background-color: rgb(254, 254, 254);
    border-left: 3px solid #a8a8a88c;
    border-top: 3px solid #a8a8a88c;
    border-radius: 15px 0px 15px 15px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.otherTables {
    display: inline-block;
    width: 40%;
    margin-left: 30%;
    text-align: center;
}

.mediumTable {
    display: inline-block;
    width: 70%;
    margin-left: 5%;
    text-align: center;
}

.reservationTable {
    display: inline-block;
    width: 80%;
    text-align: center;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: 1px solid rgba(0, 0, 0, 0) !important;
}

.ReactTable .rt-thead.-filters .rt-tr {
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none !important;
}

.ReactTable .rt-thead.-filters .rt-tr .rt-th {
    padding-left: 10px;
    padding-right: 10px;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border: 0.5px solid rgba(114, 114, 114, 0.295) !important;
    box-shadow: 1.5px 1.5px 0px 0px rgba(0, 0, 0, 0.26);
}

.ReactTable .rt-tbody .rt-td {
    border: none !important;
    border-left: 1px solid rgba(154, 149, 149, 0.07) !important;
    border-right: 1px solid rgba(154, 149, 149, 0.07) !important;
    box-shadow: 0px 4px 2px -3px rgba(0, 0, 0, 0.247);
}

.ReactTable .rt-thead.-header .rt-tr {
    box-shadow: 0px 8px 5px -3px rgba(0, 0, 0, 0.247);
    padding-bottom: 10px;
}

.ReactTable .rt-table .rt-body {
    overflow-y: scroll;
}
