/* FONT IMPORTS */

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* VARIABLES */

:root {
  --arrow-hover-color: #0069ff;
  --text-color: #777;
  --text-color-light: #999;
  --border-color: #f1e8e8;
  --bg-color: #eee;
  --neutral-color: #fff;
}

/* GENERAL */

* {
  box-sizing: border-box;
}

/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  color: var(--text-color);
  overflow: hidden;
  border-left: 3px solid #a8a8a88c;
  border-top: 3px solid #a8a8a88c;
  border-radius: 15px 0px 15px 15px;
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
  margin-right: auto;
}

.filters {
  border: 3px solid #a8a8a88c;
  border-top: 1px solid #a8a8a88c;
  border-radius: 0 0 15px 15px;
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 5px;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--arrow-hover-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: var(--text-color-light);
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 8em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.intersection {
  background: rgba(0, 255, 0, 0.2) !important;
  transition: 0.5s ease-out;
}

.intersection:hover {
  background: rgba(0, 200, 0, 0.3) !important;
  transition: 0.5s ease-out;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

.spans-container {
  margin-left: -10px;
  width: 180px;
  font-size: 15px;
  margin-top: 10px;
}

.resource-span {
  display: flex;
  border-radius: 15px;
  margin: 3px;
  color: #fff;
  padding-left: 10px;
}
