.container {
  padding: 10px;
  background-color: white;
  width: 20%;
  border-radius: 0px 15px 15px 0px;
  border-left: 1px solid #a8a8a88c;
  border-top: 3px solid #a8a8a88c;
  z-index: 100;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.selectionGroup {
  margin-top: 10px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  margin-bottom: 5px;
}

.button {
  flex: 1;
}
