.filters-container {
  background: white;
  display: flex;
  padding: 10px;
  padding-bottom: 15px;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  border: solid #a8a8a88c;
  border-width: 3px 3px 0 3px;
  border-radius: 15px 15px 0 0;
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.575);
}

.filters-container .selection-group {
  flex: 1;
  margin-right: 10px;
}

.filters-container .buttons-container {
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.filters-container .buttons-container .button {
  flex: 1;
}
