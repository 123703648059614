body {
  background-image: url("../Images/login_background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.Login {
  margin: 0 auto;
  width: 400px;
  padding: 60px 0;
}

.Login form {
  width: 400px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.719);
  border-radius: 5px;
}

.Login button {
  width: 100%;
}

.loginFormLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-bottom: 20px;
}
