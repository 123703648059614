.calendar-container {
  width: 100%;
  display: flex;
  margin-top: 10px;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
}

.calendar-container-with-filtres {
  width: 80%;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 65px;
  left: -100%;
  -webkit-transition: 0.75s ease-in-out;
  -moz-transition: 0.75s ease-in-out;
  -o-transition: 0.75s ease-in-out;
  transition: 0.75s ease-in-out;
}

.slide {
  -webkit-transform: translate(125%, 0);
  -moz-transform: translate(125%, 0);
  -o-transform: translate(125%, 0);
  -ms-transform: translate(125%, 0);
  transform: translate(125%, 0);
}
